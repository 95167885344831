import React                                from 'react';
import { GiDiamondRing }                    from 'react-icons/gi';
import { AiFillHeart }                      from 'react-icons/ai';
import { HiLightBulb }                      from 'react-icons/hi';
import { Box, Button, Flex, useMediaQuery } from '@chakra-ui/react'
import { graphql }                          from 'gatsby';
import Img                                  from 'gatsby-image';

import Link                from '@interness/web-core/src/components/elements/Link/Link';
import Heading             from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer              from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper             from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import LiveAnnouncements   from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Carousel            from '@interness/web-core/src/components/media/Carousel/Carousel';
import CallToAction        from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import RandomShopItems     from '@interness/ecommerce-addon/src/components/RandomShopItems/RandomShopItems';
import Center              from '@interness/web-core/src/components/structure/Center/Center';
import FramedImage         from '@interness/theme-sonora/src/components/FramedImage';
import FurtherLinksSection from '@interness/wedding-rings-studio-addon/src/components/FurtherLinksSection';
import SEO                 from '@interness/web-core/src/components/modules/SEO/SEO';
import Mounted             from '@interness/web-core/src/components/structure/Mounted/Mounted';
import { findMedia }       from '@interness/web-core/src/components/_helpers';


const IndexPage = ({ data }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <SEO title={'Startseite'}/>
      <Mounted>
        <Carousel>
          {data[isMobile ? 'headerImagesMobile' : 'headerImages'].images.map((image) => (
            <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
                 alt={image.file.localFile.name}/>
          ))}
        </Carousel>
      </Mounted>
      <Wrapper>
        <Center>
          <Button mt="-20px" colorScheme="brand" sx={{ textDecoration: 'none' }} as={Link} to="/terminvereinbarung">Zur
            Terminvereinbarung</Button>
        </Center>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'} icon={<GiDiamondRing/>}>Trauringstudio Reich</Heading>
          <Flex flexWrap="wrap">
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 16]}>
              <FramedImage>
                <Img fluid={findMedia(data.welcome.media, 'welcome-img-q-2').childImageSharp.fluid}/>
              </FramedImage>
            </Box>
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 16]}>
              <p>Herzlich willkommen im Trauringstudio Reich in Altenburg!</p>
              <p>Wir freuen uns, Sie in unserem exklusiven Trauringstudio am Kornmarkt 16 begrüßen zu dürfen.</p>
              <p>Unser Studio ist der ideale Ort, um die perfekten Ringe für den wichtigsten Tag in Ihrem Leben zu
                finden. Bei uns dreht sich alles um Trauringe, Eheringe, Verlobungsringe und Antragsringe. Unser Ziel
                ist es, Ihnen ein emotionales und unvergessliches Erlebnis bei der Auswahl Ihrer Ringe zu bieten.</p>
              <Button as={Link} colorScheme="brand" to="/einblicke" sx={{ textDecoration: 'none' }}>Weitere
                Einblicke</Button>
            </Box>
          </Flex>
        </section>
        <section>
          <Heading tag={'h2'} icon={<GiDiamondRing/>} subtitle="Aus unserem Trauringsortiment">Aktuelle
            Highlights</Heading>
          <RandomShopItems/>
          <Spacer/>
          <Center>
            <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} to="/trauringe">Mehr Produkte
              entdecken</Button>
          </Center>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<HiLightBulb/>}>Wissenswertes über Trauringe</Heading>
          <FurtherLinksSection/>
        </section>
        <Spacer/>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<AiFillHeart/>}>Unser Trauringkonfigurator</Heading>
          <Center>
            <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link}
                    to="/konfigurator">Zum Trauringkonfigurator</Button>
          </Center>
        </section>
        <Spacer/>
        <CallToAction description="Vereinbaren Sie noch heute Ihren Termin zur Trauringberatung"
                      button="Zur Terminvereinbarung" link="/terminvereinbarung"
                      imageFileTitle="theme-sonora-call-to-action-background-trauringe"/>
        <Spacer/>
      </Wrapper>
    </>
  )
};

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "index"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        headerImagesMobile: directusMediaCollection(name: {eq: "index-mobile"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        welcome: directusGlobalMediaCollection(name: {eq: "wedding_ring_studio_default"}) {
            name
            media {
                file: directus_files_id {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;